import React, { Component } from "react"
import { Container } from "reactstrap"
import Layout from "../../components/layout"
import { Banner, PaddingWrapper, Table } from "@igloonet-web/shared-ui"

class BankovniSpojeni extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Banner>
          <h1>Bankovní spojení</h1>
          <p>Pro vaše pohodlí je k dispozici hned několik bankovních účtů.</p>
        </Banner>

        <PaddingWrapper>
          <Container>
            <h2>Platby z Česka</h2>
            <div className="font-weight-bold">Raiffeisenbank a.s.</div>
            <div>Číslo účtu: 7261610001/5500</div>
            <div>Platby v CZK i EUR</div>
            <br />
            <div className="font-weight-bold">Fio banka, a.s.</div>
            <div>Číslo účtu: 2500090814/2010</div>
            <div>Platby pouze v CZK</div>
            <br />
            <div className="font-weight-bold">Komerční banka, a.s.</div>
            <div>Číslo účtu: 115-4387440227/0100</div>
            <div>Platby pouze v CZK</div>

            <h2 className="mt-5">Platby z EU</h2>
            <div>
              Platit lze <b>pouze v EUR v řežimu SEPA s dispozicí SHA.</b>
            </div>
            <div>
              <b>Fio banka, a.s.</b>
              <em>,V Celnici 1028/10, Praha 1, 117 21, Czech Repubdivc (CZ)</em>
            </div>
            <div>IBAN: CZ7720100000002600104558</div>
            <div>BIC/SWIFT: FIOBCZPPXXX</div>

            <div className="mt-5">
              <h2>Platby z ostatních zemí</h2>
              <b>Variabilní symbol musí být zadán ve formátu /VS/11110033.</b>
              <br />
              <b>
                Pokud máte variabilní symbol jako prosté číslo, přidejte na jeho
                začátek /VS/.
              </b>

              <h3 className="mt-5">Pokud je vaše faktura v EUR</h3>
              <div>
                Platit lze pouze s dispozicí <b>SHA </b>
                nebo <b>OUR.</b>
              </div>
              <Table
                ACnumber="2600104558/2010"
                IBANnumber="CZ77 2010 0000 0026 0010 4558"
              />

              <h3 className="mt-5">Pokud je vaše faktura v CZK</h3>
              <div>
                Platit lze <b>pouze s dispozicí OUR.</b>
              </div>
              <Table
                ACnumber="2500090814/2010"
                IBANnumber="CZ96 2010 0000 0025 0009 0814"
              />
            </div>
          </Container>
        </PaddingWrapper>
      </Layout>
    )
  }
}

export default BankovniSpojeni
